<template>
  <v-card
    :width="width"
    color="element"
    class="mt-5 rounded-xl px-sm-2 py-sm-2"
  >
    <v-card-title class="d-flex justify-space-between align-center">
      <div>
        {{ fullName }}
      </div>
      <v-img
        src="@/assets/logos/cometum/square.png"
        :height="24"
        :max-width="28"
      />
    </v-card-title>
    <v-card-subtitle class="small-text">
      {{ $t('onboarding.investorApproval.cardSubtitle') }}
    </v-card-subtitle>
    <v-card-text class="mt-5">
      <v-row align="center">
        <v-col cols="3" class="pr-0 small-text">{{ $t('onboarding.investorApproval.cardItemOne') }}</v-col>
        <v-col ref="bvdhAccountId" id="bvdhAccountId" cols="7" class="defaultText--text medium-text">
          DE96 7001 1700 9900 0020 51
        </v-col>
        <v-col class="pl-0">
          <v-btn icon @click="copyText('bvdhAccountId')">
            <v-icon size="15" :color="copied.bvdhAccountId ? 'success': ''">mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0" align="center">
        <v-col cols="3" class="pr-0 small-text pt-0">{{ $t('onboarding.investorApproval.cardItemTwo') }}</v-col>
        <v-col ref="paymentId" id="paymentId" cols="7" class="defaultText--text medium-text pt-0">
          {{ balance.paymentId }}
        </v-col>
        <v-col class="pl-0 pt-0">
          <v-btn icon @click="copyText('paymentId')">
            <v-icon size="15" :color="copied.paymentId ? 'success': ''">mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileCard',
  props: {
    width: {
      type: [String, Number],
      default: 400,
    },
  },
  data() {
    return {
      copied: {
        bvdhAccountId: false,
        paymentId: false,
      },
    };
  },
  computed: {
    ...mapGetters('user', [
      'balance',
      'fullName',
    ]),
  },
  methods: {
    copyText(ref) {
      const text = this.$refs[ref].innerText.replace(/\s/g, '');
      navigator.clipboard.writeText(text).then(() => {
        this.copied[ref] = true;
        setTimeout(() => {
          this.copied[ref] = false;
        }, 2000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  font-size: 10px;
}
.medium-text {
  font-size: 12px;
}
</style>
